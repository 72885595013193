<!-- ========== Left Sidebar Start ========== -->


<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>


      <li class="menu-title">{{GetWordText('Home')}}</li>

      <li><a   routerLink="/slider" aria-expanded="false"><i class="mdi mdi-24px mdi-image"></i><span>  {{GetWordText('slider')}}  </span></a></li>
      <li><a   routerLink="/home_about" aria-expanded="false"><i class="mdi mdi-24px mdi-image"></i><span>  {{GetWordText('home_about')}}  </span></a></li>
      <li><a   routerLink="/home_content" aria-expanded="false"><i class="mdi mdi-24px mdi-image"></i><span>  {{GetWordText('home_content')}}  </span></a></li>
      <li><a   routerLink="/home_counter" aria-expanded="false"><i class="mdi mdi-24px mdi-image"></i><span>  {{GetWordText('home_counter')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('blog')}}</li>
      <li><a   routerLink="/blog" aria-expanded="false"><i class="mdi mdi-24px mdi-blogger"></i><span>  {{GetWordText('blog-list')}}  </span></a></li>
      <li><a   routerLink="/blog/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-blogger"></i><span>  {{GetWordText('blog-deleted')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('news')}}</li>
      <li><a   routerLink="/news" aria-expanded="false"><i class="mdi mdi-24px mdi-newspaper"></i><span>  {{GetWordText('news-list')}}  </span></a></li>
      <li><a   routerLink="/news/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-newspaper"></i><span>  {{GetWordText('news-deleted')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('events')}}</li>
      <li><a   routerLink="/events" aria-expanded="false"><i class="mdi mdi-24px mdi-calendar"></i><span>  {{GetWordText('events-list')}}  </span></a></li>
      <li><a   routerLink="/events/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-calendar"></i><span>  {{GetWordText('events-deleted')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('app_text')}}</li>
      <li><a   routerLink="/app_text" aria-expanded="false"><i class="mdi mdi-24px mdi-translate"></i><span>  {{GetWordText('app_text-list')}}  </span></a></li>
      <li><a   routerLink="/app_text/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-translate"></i><span>  {{GetWordText('app_text-deleted')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('services')}}</li>
      <li><a   routerLink="/services" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('services-list')}}  </span></a></li>
      <li><a   routerLink="/services/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('services-deleted')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('competencies')}}</li>
      <li><a   routerLink="/competencies" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('competencies-list')}}  </span></a></li>
      <li><a   routerLink="/competencies/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('competencies-deleted')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('features')}}</li>
      <li><a   routerLink="/features" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('features-list')}}  </span></a></li>
      <li><a   routerLink="/features/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('features-deleted')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('jobs')}}</li>
      <li><a   routerLink="/jobs" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('jobs-list')}}  </span></a></li>
      <li><a   routerLink="/jobs/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('jobs-deleted')}}  </span></a></li>

      <li class="menu-title">{{GetWordText('suppliers_logos')}}</li>
      <li><a   routerLink="/suppliers_logos" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('suppliers_logos-list')}}  </span></a></li>
      <li><a   routerLink="/suppliers_logos/deleted" aria-expanded="false"><i class="mdi mdi-24px mdi-star-box"></i><span>  {{GetWordText('suppliers_logos-deleted')}}  </span></a></li>

    </ul>


  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
