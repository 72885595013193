import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Language {

  lang_words: Array<any>;

  constructor() {

    this.lang_words = [
      {text_key:"_", text_ar: " ", text_en: " "},
      {text_key:"home", text_ar:"الرئيسية", text_en:"Home"},
      {text_key:"login_content",text_ar:"سجل دخول الى لوحة التحكم باستخدام البريد الالكتروني وكلمة المرور ",text_en: " Log in to the control panel using your email and password "},
      {text_key: "login", text_ar: " دخول ", text_en: " Login "},
      {text_key:"password",text_ar: " كلمة المرور ",text_en: " Password "},
      {text_key:"email", text_ar: "البريد الالكتروني", text_en: " Email "},
      {text_key:"slider",text_ar: "الصور المتحركة", text_en: "Slider"},
      {text_key:"add_new",text_ar: "إضافة جديد", text_en: "Add New"},
      {text_key:"text1_ar",text_ar: "السطر الاول AR", text_en: "Line 1 AR Text"},
      {text_key:"text2_ar",text_ar: "السطر الثاني AR", text_en: "Line 2 AR Text"},
      {text_key:"text3_ar",text_ar: "السطر الثالث AR", text_en: "Line 3 AR Text"},
      {text_key:"text1_en",text_ar: "السطر الاول EN", text_en: "Line 1 AR Text"},
      {text_key:"text2_en",text_ar: "السطر الثاني EN", text_en: "Line 2 AR Text"},
      {text_key:"text3_en",text_ar: "السطر الثالث EN", text_en: "Line 3 AR Text"},
      {text_key:"photo",text_ar: "الصورة", text_en: "Photo"},
      {text_key:"btn_link",text_ar: "الرابط", text_en: "Btn Link"},
      {text_key:"save",text_ar: "حفظ", text_en: "Save"},
      {text_key:"Home",text_ar: "الرئيسية", text_en: "Home"},
      {text_key:"blog",text_ar: "المدونة", text_en: "Blog"},
      {text_key:"blog-list",text_ar: "التدوينات", text_en: "Blogs "},
      {text_key:"blog-deleted",text_ar: "التدوينات المحذوفة", text_en: "Blogs Deleted"},
      {text_key:"news",text_ar: "الاخبار", text_en: "News"},
      {text_key:"news-list",text_ar: "قائمة الاخبار", text_en: "News"},
      {text_key:"news-deleted",text_ar: "الاخبار المحذوفة", text_en: "News Deleted"},
      {text_key:"events",text_ar: "الاحداث", text_en: "Events"},
      {text_key:"events-list",text_ar: "قائمة الاحداث", text_en: "Events"},
      {text_key:"events-deleted",text_ar: "الاحداث المحذوفة", text_en: "Events Deleted"},
      {text_key:"all",text_ar: "", text_en: "All"},
      {text_key:"title_ar",text_ar: "العنوان AR", text_en: "Title AR"},
      {text_key:"title_en",text_ar: "العنوان EN", text_en: "Title EN"},
      {text_key:"content_ar",text_ar: "المحتوي AR", text_en: "Content AR"},
      {text_key:"content_en",text_ar: "المحتوي  EN", text_en: "Content EN"},
      {text_key:"search",text_ar: "البحث", text_en: "Search"},
      {text_key:"actions",text_ar: "الاجراءات", text_en: "Actions"},
      {text_key:"Showing",text_ar: "عرض", text_en: "Showing"},
      {text_key:"show",text_ar: "عرض", text_en: "Show"},
      {text_key:"to",text_ar: "الى", text_en: "to"},
      {text_key:"of",text_ar: "من", text_en: "of"},
      {text_key:"entries",text_ar: "مدخل", text_en: "entries"},
      {text_key:"app_text",text_ar: "الترجمة والنصوص", text_en: "Text & Translations "},
      {text_key:"text_ar",text_ar: "النص بالعربية", text_en: "Text AR"},
      {text_key:"text_en",text_ar: "النص بالانجليزية ", text_en: "Text EN"},
      {text_key:"text_key",text_en: "Text Key", text_ar: "المفتاح"},
      {text_key:"app_text-list",text_ar: "قائمة النصوص", text_en: "Text list"},
      {text_key:"app_text-deleted",text_ar: "النصوص المحذوفة", text_en: "Text Deleted"},
      {text_key:"home_counter",text_ar: "الاعداد", text_en: "Counter "},
      {text_key:"name_ar",text_ar: "الاسم بالعربية", text_en: "Name Ar"},
      {text_key:"name_en",text_ar: "الاسم بالانجليزية", text_en: "name En"},
      {text_key:"home_about",text_ar: "من نحن", text_en: "About"},
      {text_key:"home_content",text_ar: "محتوي الرئيسية", text_en: "Home Content"},
      {text_key:"competencies",text_ar: "الكفائات", text_en: "Competencies"},
      {text_key:"competencies-list",text_ar: "قائمة الكفائات", text_en: "Competencies List"},
      {text_key:"competencies-deleted",text_ar: "الكفائات المحذوفة", text_en: "Deleted Competencies"},
      {text_key:"icon",text_ar: "الصورة الرمزية", text_en: "Icon "},
      {text_key:"photo_1",text_ar: "الصورة 1", text_en: "Photo 1"},
      {text_key:"photo_2",text_ar: "الصورة 2", text_en: "Photo 2"},
      {text_key:"photo_3",text_ar: "الصورة 3", text_en: "Photo 3"},
      {text_key:"photo_4",text_ar: "الصورة 4", text_en: "Photo 4"},
      {text_key:"list_en",text_ar: "القائمة EN", text_en: "List EN"},
      {text_key:"list_ar",text_ar: "القائمة AR", text_en: "List AR"},
      {text_key:"features",text_ar: "المميزات", text_en: "Features"},
      {text_key:"features-list",text_ar: "المميزات", text_en: "Features"},
      {text_key:"features-deleted",text_ar: "المميزات المحذوفة", text_en: "Deleted Features"},
      {text_key:"services",text_ar: "الخدمات", text_en: "Services"},
      {text_key:"services-list",text_ar: "قائمة الخدمات", text_en: "Services List"},
      {text_key:"services-deleted",text_ar: "الخدمات المحذوفة", text_en: "Services Deleted"},
      {text_key:"jobs",text_ar: "الوظائف", text_en: "Jobs"},
      {text_key:"jobs-list",text_ar: "قائمة الوظائف", text_en: "Jobs List"},
      {text_key:"jobs-deleted",text_ar: "الوظائف المحذوفة", text_en: "Deleted Jobs"},
      {text_key:"suppliers_logos",text_ar: "الشعارات", text_en: "Logos"},
      {text_key:"suppliers_logos-list",text_ar: "الشعارات", text_en: "Logos"},
      {text_key:"suppliers_logos-deleted",text_ar: "الشعارات المحذوفة", text_en: "Deleted Logos"},
      {text_key:"welcome",text_ar: "مرحبا", text_en: "welcome"},
      {text_key:"welcome_text",text_ar: "نتمني لك يوم عمل جيد", text_en: "have nice day "},
      {text_key:"Password",text_ar: "كلمة المرور", text_en: "Password"},
      {text_key:"Remember_me",text_ar: "تذكرني", text_en: "Remember me"},
      {text_key:"change_lang",text_ar: "تبديل اللغة", text_en: "Change lang"},
      {text_key:"copy_rights",text_ar: "جميع الحقوق محفوظة", text_en: "copy rights"},
      {text_key:"company_name",text_ar: "Kayan", text_en: "Kayan"},
      {text_key:"save_update",text_ar: "حفظ التغييرات", text_en: "Save Update"},
      {text_key:"job_title_ar",text_ar: "المسمي الوظيفي  AR", text_en: "Job title AR"},
      {text_key:"job_title_en",text_ar: "المسمي الوظيفي EN", text_en: "Job title EN"},
      {text_key:"logo",text_ar: "الشعار", text_en: "logo"},
      {text_key:"start_date",text_ar: "تاريخ البدء", text_en: "Start Date"},
      {text_key:"end_date",text_ar: "تاريخ الانتهاء", text_en: "End Date"},
      {text_key:"counter",text_ar: "الرقم", text_en: "counter"},
      {text_key:"",text_ar: "", text_en: ""},
      {text_key:"",text_ar: "", text_en: ""},
      {text_key:"",text_ar: "", text_en: ""},
      {text_key:"",text_ar: "", text_en: ""},
      {text_key:"",text_ar: "", text_en: ""},
    ];
//
  }

}

